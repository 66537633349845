// Styles file for 'trusteddownpaymentsurvey.com'.
// Use 'styles.js' in the 'harpquiz' directory as a guide.

import { css, keyframes } from 'styled-components';
import images from './images';

// Colors
const colors = {
  link: '#b9b9b9',
  background: '#eee',
  titleColor: '#333',
  buttonColor: '#486ab6',
  headerBackground: '#fff',
  modalLink: '#333',
  progressBarColor: '#12ab2d',
  headerColor: '#fff',
  sliderFillColor: '#8d989b',
};

// Header
const HeaderContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px; /* Don't forget to change the min-height calculation for SurveyBackground */
  background: ${colors.headerBackground};
  @media (max-width: 801px) {
    height: 70px;
  }
`;

const HeaderImage = css`
  width: 50%;
  max-width: 240px;
  margin-bottom: 0;
  @media (max-width: 801px) {
    max-width: 180px;
  }
`;

// Only used when sites background color is needed (check out `100kdebt.com`)
const AppWrapper = css`
  /* background: url(${images.Banner}) 50% 80px/100% 45vh no-repeat #eee;
  @media (max-width: 801px) {
    background-position: 50% 70px;
    background-size: 150% 30vh;
  } */
`;

const StyledBg = css`
  background-position: 50% 80px;
  background-size: 100% 45vh;
  @media (max-width: 801px) {
    background-position: 50% 70px !important;
    background-size: 150% 30vh !important;
  }
`;

// Main Background
const SurveyBackground = css`
  padding: 30px 0 20px;
  min-height: calc(100vh - 92px);

  /* background-color: ${colors.background}; */

  @media (max-width: 801px) {
    padding: 0 0 20px;
    min-height: calc(100vh - 70px);
  }
`;

const SurveyContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  margin: 20px auto 25px;
  max-width: 630px;
  border-radius: 2px;
  @media (max-width: 801px) {
    margin: 10px auto 25px;
  }
`;

const QuestionNumber = css`
  position: absolute;
  top: 5px;
  left: 5px;
  color: #000;
`;

const FormContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0;
  min-height: 350px;
  width: 100%;
  padding: 30px 16px 20px;
  box-shadow: #bcbcbc 2px 2px 6px;
`;

const GlobalSealImg = css`
  margin: 20px;
  max-width: 110px;
`;

// Containers
// Multiple choice
const ChoiceContainer = css`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 0 0 0 45px;
  height: 59px;
  margin: 0 0 15px;
  transition: background-color 230ms ease;
  background: url(${images.Unselected}) 15px 50%/20px no-repeat #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out;
  &:hover {
    background-color: #eaeaea;
    @media (max-width: 801px) {
      background-color: #f7f7f7;
    }
  }
  span {
    font-size: 1em;
    @media (max-width: 801px) {
      font-size: 12px;
    }
  }
`;

// Styles for selected radio button (multiple-choice)
const InputContainer = css`
  width: 100%;
  display: flex;
  justify-content: center;
  > input:checked + label {
    background: url(${images.Selected}) 15px 50%/20px no-repeat #f7f7f7;
    transition: background-color 0.25s ease-in-out;
    &:hover {
      background-color: #eaeaea;
    }
  }

  label {
    width: 100%;
    max-width: 400px;
  }

  /* Excellent styling */
  > input[id='excellent'] + label {
    background: url(${images.Excellent}) 95% 50%/30px no-repeat,
      url(${images.Unselected}) 15px 50%/20px no-repeat #f7f7f7;
    transition: background-color 0.25s ease-in-out;
    &:hover {
      background-color: #eaeaea;
    }
  }
  > input[id='excellent']:checked + label {
    background: url(${images.Excellent}) 95% 50%/30px no-repeat,
      url(${images.Selected}) 15px 50%/20px no-repeat #f7f7f7;
    transition: background-color 0.25s ease-in-out;
    &:hover {
      background-color: #eaeaea;
    }
  }

  /* Good styling */
  > input[id='good'] + label {
    background: url(${images.Good}) 95% 50%/30px no-repeat,
      url(${images.Unselected}) 15px 50%/20px no-repeat #f7f7f7;
    transition: background-color 0.25s ease-in-out;
    &:hover {
      background-color: #eaeaea;
    }
  }
  > input[id='good']:checked + label {
    background: url(${images.Good}) 95% 50%/30px no-repeat,
      url(${images.Selected}) 15px 50%/20px no-repeat #f7f7f7;
    transition: background-color 0.25s ease-in-out;
    &:hover {
      background-color: #eaeaea;
    }
  }

  /* Average styling */
  > input[id='average'] + label {
    background: url(${images.Average}) 95% 50%/30px no-repeat,
      url(${images.Unselected}) 15px 50%/20px no-repeat #f7f7f7;
    transition: background-color 0.25s ease-in-out;
    &:hover {
      background-color: #eaeaea;
    }
  }
  > input[id='average']:checked + label {
    background: url(${images.Average}) 95% 50%/30px no-repeat,
      url(${images.Selected}) 15px 50%/20px no-repeat #f7f7f7;
    transition: background-color 0.25s ease-in-out;
    &:hover {
      background-color: #eaeaea;
    }
  }

  /* Fair styling */
  > input[id='fair'] + label {
    background: url(${images.Fair}) 95% 50%/30px no-repeat,
      url(${images.Unselected}) 15px 50%/20px no-repeat #f7f7f7;
    transition: background-color 0.25s ease-in-out;
    &:hover {
      background-color: #eaeaea;
    }
  }
  > input[id='fair']:checked + label {
    background: url(${images.Fair}) 95% 50%/30px no-repeat,
      url(${images.Selected}) 15px 50%/20px no-repeat #f7f7f7;
    transition: background-color 0.25s ease-in-out;
    &:hover {
      background-color: #eaeaea;
    }
  }

  /* Poor styling */
  > input[id='poor'] + label {
    background: url(${images.Poor}) 95% 50%/30px no-repeat,
      url(${images.Unselected}) 15px 50%/20px no-repeat #f7f7f7;
    transition: background-color 0.25s ease-in-out;
    &:hover {
      background-color: #eaeaea;
    }
  }
  > input[id='poor']:checked + label {
    background: url(${images.Poor}) 95% 50%/30px no-repeat,
      url(${images.Selected}) 15px 50%/20px no-repeat #f7f7f7;
    transition: background-color 0.25s ease-in-out;
    &:hover {
      background-color: #eaeaea;
    }
  }
`;

// Input
const inputStyle = css`
  width: 100%;
  max-width: 400px;
  height: 57px;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 16px;
  margin-top: 10px;
  background: #fff;
  text-align: center;
  border: ${props =>
    props.border
      ? '1px solid red'
      : '1px solid #bbb'} !important; /* overrides initial setting*/
`;

const InputField = css`
  ${inputStyle}
  border: ${props => props.border};
  

  @media (max-width: 80px) {
    width: 100%;
  }
`;

const StyledTextArea = css`
  ${inputStyle}
  border-radius: 5px;
  border: ${props => props.border};
`;

const AddressContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;

  p {
    margin-bottom: 0;
  }
`;

// Dropdown
const StyledSelect = css`
  ${inputStyle}
  margin: 0 0 15px;
  padding-left: 20px;
  border: 1px inset #bbb;
  -webkit-appearance: none;
  background: url(${images.Dropdown}) 98% 50%/ 15px no-repeat #fff;
  &.stateDropdown {
    margin-top: 10px;
  }
`;

// Zip input
const StyledZip = css`
  ${inputStyle}
  margin-bottom: 12px;
  color: #555;
  border: 1px solid #000;
  border: ${props =>
    props.border
      ? '1px solid red'
      : '1px solid #bbb'} !important; /* overrides initial setting*/
  padding: 0 20px;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const FinalInputContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: auto;

  input,
  select {
    margin-top: 10px;
    margin-bottom: 0;
    height: 40px;
    text-align: center;
  }
`;

// Hidden inputs
const HiddenContainer = css`
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  width: 100%;

  p,
  input,
  select {
    margin: 5px;
  }
`;

// Survey styles
const QuestionTitle = css`
  color: ${colors.titleColor};
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 25px;
  text-align: center;
  width: 100%;
  @media (max-width: 801px) {
    &#questionTitle-path-choice {
      margin-top: 5px;
    }
  }
`;

const InputTitle = css`
  margin-bottom: 0;
  margin-top: 10px;
`;

const Subtitle = css`
  text-align: center;
  margin-bottom: 0;
  color: #333;
  &.subtitle-credit_rating,
  &.subtitle-address,
  &.subtitle-estimated_debt,
  &.subtitle-mortgage_rate,
  &.subtitle-first_name {
    margin: -20px 0 20px;
  }
`;

const TipContainer = css`
  text-align: center;
  background: #fff;
  padding: 0;
  border-radius: 3px;
  width: 100%;
  max-width: 400px;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 15px;
  @media (max-width: 801px) {
    padding: 0 20px;
  }
`;

const QuestionContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 50px);
  padding: 0;
  @media (max-width: 801px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const QuestionBodyContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

// Slider
const SliderBox = css`
  margin-bottom: 20px;
`;
const StyledSlider = css`
  -webkit-appearance: none;
  width: 100%;
  max-width: 400px;
  margin: auto;
  height: 35px;
`;

const StyledThumb = css`
  background: url(${images.SliderArrows}) 47% 47%/14px no-repeat #333;
  border: 0 solid #333;
  width: 29px;
  height: 29px;
  border-radius: 18px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin-top: 6px;
`;

const DisplayNumber = css`
  font-size: ${props => (props.primary ? '28px' : '16px')};
  font-weight: ${props => props.primary && '400'};
  padding: 10px;
  margin-bottom: 0;
  text-align: center;
  @media (max-width: 801px) {
    font-size: ${props => (props.primary ? '24px' : '16px')};
  }
`;

const NumberContainer = css`
  display: flex;
  justify-content: space-between;
  width: inherit;
  width: 100%;
  max-width: 400px;
  margin: auto;
`;

const SliderContainer = css`
  width: inherit;
  height: 12px;
  border-radius: 8px;
  border: 1px solid #313e54;
  margin-top: 15px;
`;

const FillContainer = css`
  position: absolute;
  height: 12px;
  border-radius: 8px 0 0 8px;
  background: ${colors.sliderFillColor};
  width: ${props => `${props.width}%`};
  margin-top: 15px;
`;

// ProgressBar
const ProgressContainer = css`
  margin: auto;
  width: calc(100% - 30px);
  max-width: 400px;
  height: 14px;
  background: #f7f7f7;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto 6px;
  @media (max-width: 801px) {
    margin: 10px auto 0;
  }
`;

// Progressbar animation
// const ProgressBarStripes = keyframes`
//   /* Property not used */
// `;

const StatusBar = css`
  background-size: 50px 50px;
  width: ${props => props.width};
  height: inherit;
  background-color: ${colors.progressBarColor};
  -webkit-transition: width 0.75s ease;
  -o-transition: width 0.75s ease;
  transition: width 0.25s ease;
  border: 2px solid #f7f7f7;
  border-radius: 10px 0 0 10px;
`;

const NumberStatus = css`
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  margin-top: 10px;

  &::after {
    content: ' complete';
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

// buttons
const SubmitButton = css`
  box-sizing: border-box;
  margin-bottom: 0;
  font-family: sans-serif;
  padding: 1vh 2vw;
  border-radius: 4px;
  background-color: ${props => props.color || colors.buttonColor};
  border: 0;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  height: 54px;
  outline: 0;

  &:hover {
    opacity: 0.9;
  }
`;

const NextButtonIcon = css`
  font-size: 16px;
  margin-left: 10px;
`;

const BackButton = css`
  font-family: sans-serif;
  font-size: 16px;
  border: none;
  background: transparent;
  color: ${colors.buttonColor};
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const ButtonContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 50px);
  max-width: 400px;
  &#final-button-container {
    margin: 20px 0;
  }
  @media (max-width: 801px) {
    width: 100%;
    padding: 0 20px;
    margin: 0 0 20px;
  }
`;

// Footer
const FooterContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4vh 4vw;
  background: #eff3f6;

  p {
    margin-bottom: 0;
  }

  a {
    color: ${colors.link};
  }
`;

const DisclosureContainer = css`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;

  a {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 0.8em;
  }
`;

const ModalButtonContainer = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  margin: 2vh auto;
  border-top: 1px solid #c9c9c9;
  padding-top: 1vh;

  span {
    color: ${colors.modalLink};
  }

  @media (max-width: 500px) {
    width: 98%;
  }
`;

const ModalButton = css`
  border: 0;
  background: inherit;
  color: ${colors.modalLink};
  cursor: pointer;
  font-size: 13px;

  &:hover {
    text-decoration: underline;
  }
`;

// Modal
const ModalBackground = css`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
`;
const ModalContainer = css`
  margin: auto;
  background: white;
  width: ${props => (props.primary ? '50vw' : '70vw')};
  height: ${props => (props.primary ? '60vh' : '80vh')};
  padding: 3vh 3vw;
  border-radius: 10px;
  overflow: auto;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const ExitButton = css`
  top: ${props => (props.primary ? '19vh' : '9vh')};
  right: ${props => (props.primary ? '24vw' : '14vw')};
  position: absolute;
  border-radius: 50%;
  border: 1px solid #eee;
  font-size: 1.5em;
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    background: #969696;
    color: #fff;
  }

  @media (max-width: 900px) {
    right: 3vw;
  }
`;

const ModalInformation = css`
  display: flex;
  flex-direction: column;
  line-height: 1.5em;

  br {
    line-height: 2em;
  }

  @media (max-width: 600px) {
    font-size: 0.8em;
  }
`;

const BoldText = css`
  font-size: 1.2em;
  color: ${props => props.primary && '#C1794C'};
  text-align: ${props => props.primary && 'center'};
`;

const Text = css`
  color: #989898;
  font-size: 0.9em;
  font-weight: ${props => props.primary && '700'};
  margin-bottom: ${props => props.primary && '0'};
`;

const FooterText = css`
  margin-top: ${props => props.primary && '-10px'};
  white-space: pre-line;
  text-align: center;
  font-size: 12px;
  color: ${({ primary }) => primary && '#333'};
  margin-bottom: 5px;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const ImageList = css`
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const HeadlineContainer = css`
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin: 10px auto 0;
  @media (max-width: 801px) {
    max-width: calc(100% - 30px);
  }
`;

const HeadlineHeader = css`
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #fff;
  color: ${colors.headerColor};
  @media (max-width: 801px) {
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
  }
`;

const SubHeader = css`
  font-size: 20px;
  font-weight: 400;
  color: ${colors.headerColor};
  margin-bottom: 30px;
`;

const SmallText = css`
  font-size: ${props => (props.secondary ? '0.9em' : '0.7em')};
  font-weight: ${props => (props.primary ? '700' : '200')};
  max-width: 500px;
  margin: auto;
`;

const ErrorText = css`
  color: #ee0000;
  text-align: center;
  font-size: 0.9em;
  margin-bottom: 10px;
`;

const FinalDisclosureContainer = css`
  padding: 0;
  min-height: 20px;
  max-width: 800px;
  margin: auto;
  background: transparent;
  line-height: 1.5;

  p {
    margin-bottom: 2px;
    font-size: 12px;
    color: #767676;
  }
`;

// Loading Spinner
const Bounce = keyframes`
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
`;

const SpinnerContainer = css`
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
`;

const Spinner = css`
  width: 18px;
  height: 18px;
  background-color: #5598c1;
  border-radius: 100%;
  display: inline-block;
  animation: ${Bounce} 1.4s infinite ease-in-out both;
  animation-delay: ${props => props.delay};
`;

const LoadingContainer = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
`;

const SpinnerMainContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  padding: 40px;
  background: #fff;
`;

const CongratsHolder = css`
  /* Style property not used in this survey */
`;

const CheckboxWrapper = css`
  display: flex;
  flex-direction: column;
`;

const CheckboxContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0 20px;
  width: 100%;
  max-width: 400px;
`;

const CheckboxLabel = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 30px;
  width: 100%;

  > input {
    -webkit-appearance: none;
  }

  > input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    top: 0;
    left: 0;
    border: 1px solid #dedede;
    border-radius: 3px;
    background-color: white;
    box-shadow: inset #777 0 0 2px;
    margin: 4px;
  }
  > input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 6px;
    height: 12px;
    border: solid grey;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 3px;
    left: 9px;
    margin: 4px;
  }

  @media (max-width: 500px) {
    font-size: 0.8em;
  }
`;

const CheckboxParagraph = css`
  margin-left: 35px;
  margin-bottom: 0;
  font-size: 16px;
  text-align: left;
  width: 100%;
  @media (max-width: 801px) {
    font-size: 13px;
  }
`;

// Change Address container on final-frame step
const ChangeAddress = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;

  p,
  button {
    margin-bottom: 0;
  }
`;

const AddressSubtext = css`
  width: 100%;
  margin-bottom: 0;
  max-width: 400px;
`;

const LenderDropdown = css`
  background: #fff;
  margin-bottom: 10px;
  padding: 0 10px;
  position: absolute;
  width: 100%;

  p {
    margin-bottom: 0;
    border-bottom: 1px solid #ddd;
    padding: 5px;
    &:hover,
    &:focus {
      background: ${colors.link};
      color: #fff;
      cursor: pointer;
    }
  }
`;

export default {
  HeaderContainer,
  HeaderImage,
  AppWrapper,
  StyledBg,
  SurveyBackground,
  SurveyContainer,
  QuestionNumber,
  FormContainer,
  GlobalSealImg,
  ChoiceContainer,
  InputContainer,
  inputStyle,
  InputField,
  StyledTextArea,
  AddressContainer,
  StyledSelect,
  StyledZip,
  FinalInputContainer,
  HiddenContainer,
  QuestionTitle,
  InputTitle,
  Subtitle,
  TipContainer,
  QuestionContainer,
  QuestionBodyContainer,
  SliderBox,
  StyledSlider,
  StyledThumb,
  DisplayNumber,
  NumberContainer,
  SliderContainer,
  FillContainer,
  ProgressContainer,
  StatusBar,
  NumberStatus,
  SubmitButton,
  NextButtonIcon,
  BackButton,
  ButtonContainer,
  FooterContainer,
  DisclosureContainer,
  ModalButtonContainer,
  ModalButton,
  ModalBackground,
  ModalContainer,
  ExitButton,
  ModalInformation,
  BoldText,
  Text,
  FooterText,
  ImageList,
  HeadlineContainer,
  HeadlineHeader,
  SubHeader,
  SmallText,
  ErrorText,
  FinalDisclosureContainer,
  SpinnerContainer,
  Spinner,
  LoadingContainer,
  SpinnerMainContainer,
  CongratsHolder,
  CheckboxWrapper,
  CheckboxContainer,
  CheckboxLabel,
  CheckboxParagraph,
  ChangeAddress,
  AddressSubtext,
  LenderDropdown,
};
